/* @font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Light"),
    url("./fonts/Gilroy-FREE/Gilroy-Light.otf") format("truetype");
  font-weight: light;
} */

.leckerli-one-regular {
  font-family: "Leckerli One", serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  min-width: 375px;
  max-width: 100vw;
  /* position: absolute; */
  /* overflow: hidden; */
  height: '100%';
  width: '100%';
  background-color: #FFFFFF;

  box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 0.6);

}

/* body{
  background-color: #363535;
} */
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  /* outline: none  !important; */
}

ul {
  margin: 0;
  margin-left: 0;
}

.grip-dots {
  background-image: url("../public/assets/grip-dots-duo.svg");
}

.sortable-list .item {
  list-style: none;
  display: flex;
  cursor: move;
}

.item i {
  color: #474747;
  font-size: 1.5rem;
  opacity: 0.8;
}


.item.dragging {
  opacity: 0.6;
}

.item.dragging :where(.details, i) {
  opacity: 0;
}

#general-form-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

@media screen and (max-width: 515px) {
  #general-form-content {
    flex-direction: column;
  }

  .settings-nav button {
    font-size: 0.75rem;
  }

  .settings-nav {
    flex-direction: column;
  }
}

#app-content {
  /* background-image: url("../public/assets/Gradient_01_Frame1.png");
  background-size: cover; */
  /* padding-top: 1rem; */
}

* {
  margin: 0;
}

#navbar,
.navbar {
  /* position: fixed; */
  z-index: 2000;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  /* width: 100%; */
  /* Full width */
}

.main-modal {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0.9) 100%);

  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.home-modal {
  position: absolute;
  /* margin-top:'0.5rem'; */
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgba(0, 0, 0, 0.9);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 50%,
      rgba(0, 0, 0, 1) 100%);
  background-size: cover;
  border-radius: 15px;
  border: 2px solid #FFFFFF;
  border-bottom: none;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.6);
  width: 80%;
  height: fit-content;
  max-height: 85vh;
  margin: auto;
  padding: 0.5rem;
  z-index: 99999;
}

.confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 40%,
      rgba(0, 0, 0, 0.9) 100%);
  background-size: cover;
  border-radius: 15px;
  border: 2px solid #FFFFFF;
  border-bottom: none;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.6);
  width: 80%;
  height: fit-content;
  max-height: 85vh;

  z-index: 99999;
}

.modal-close-button {
  position: absolute;
  top: 0.25rem;
  right: 0.75rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
}

.qr-data-button {
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
}

.modal-close-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

#canvas {
  background-color: #fff;
  padding: 10px;
}

.QR-frame {
  animation: QR-init 0.6s ease-in-out;
}

canvas {
  border-radius: 5px;
  /* margin: 0 10px; */
  border: 2px outset #000;
  box-shadow: 0px 0px 10px 5px rgba(94, 97, 67, 0.313);
}

.profile-container {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}

.user-info-top {
  /* width: 100%; */
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  justify-content: space-evenly;
}

.user-info-top>* {
  flex: 1;
  /* border: 1px solid blue; */
}

.user-info-bottom>* {
  /* flex: 1; */
  /* border: 1px solid green; */
}

.profile-container>* {
  /* border: 1px solid red; */
  /* height: 40%; */
  flex: 1;
  padding: 0.5rem;
}

.metric-list-item,
.persona-item-text,
.asset-item-text,
#asset-item-text,
.persona-list-card h6,
.asset-list-card h6 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.qr-option-li li {
  margin-left: 0;
}

.qr-option-li div:hover {
  background-color: #000000be;
  color: rgb(120, 203, 233);
  cursor: pointer;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2)
}

.qr-list-card:hover,
#list-item:hover {
  background-color: rgba(61, 255, 158, 0.628);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px)
}

.persona-list-card:hover {
  background-color: rgba(61, 255, 158, 0.628);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

/* #persona-list-card:hover, */
.asset-list-card:hover {
  background-color: rgba(61, 255, 158, 0.628);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px)
}

.redirect-button {
  border: 1px solid #33ff00;
  border-radius: 5px;
  background-color: rgb(61, 255, 158);
  color: rgb(39, 39, 38);
  /* 0 5px 0 5px; */
  width: 155px;
  /* width: fit-content; */
  height: 2.25rem;
  padding: 0.25rem 0.5rem;
  margin: auto;
  cursor: pointer;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.6),
    inset 0px 0px 10px 2px rgba(0, 0, 0, 0.3);
}

.redirect-button:hover {
  background-color: rgb(116, 188, 246);
}

.save-button,
#save-button {
  color: #000000;
  font-size: 16px;
  font-weight: 100;
  border-radius: 5px;
  /* border-color: #33ff00; */
  border-color: #000000;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background-color: #ffffff17;
  /* background-color: #d2ffd2a6; */
  /* background-color: #187c18a6; */
  /* background-onhover-color:#dbdbdb; */
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.nav-link {
  color: #000000;
  /* font-size: 16px; */
  text-align: center;
  font-weight: 100;
  border-radius: 5px;
  min-width: 88px;
  padding: '0.25rem';
  width: 100%;
  /* border-color: #33ff00; */
  border-color: #000000;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background-color: #ffffff17;
}

.nav-link:first-child {
  border-radius: 0px 5px 0px 5px;
}

.nav-link:last-child {
  border-radius: 5px 0px 5px 0px;
}

.nav-link:hover {
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  outline: 0.25px solid #000;
}

.nav-link a {
  text-decoration: none;
  color: #000;

}

.grecaptcha-badge {
  /* top: 15rem !important; */
}

/* .settings-nav {
  position: sticky;
  top: 0;
  display: flex; */
/* flex-direction: row;
  justify-content: center; */
/* align-items: center; */
/* padding: 0.25rem; */
/* box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.3); */
/* border: 2px inset rgba(0, 0, 0, 0.3); */
/* border-top: none; */
/* border-bottom: none; */
/* border-radius: 8px;
  margin: auto;
  position: relative;
  top: -40px;
} */

.settings-nav button {
  /* background-color: rgba(234, 218, 218, 0); */
  border: none;
  /* border: 1px solid #fefefe; */
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0px 3px;
  /* border-radius: 3px 3px 0 0; */
  margin: 0.25rem;
  /* background: linear-gradient(
    0deg,
    rgba(250, 250, 250, 0) 10%,
    rgba(250, 250, 250, 0.1) 70%,
    rgba(250, 250, 250, 0.5) 100%
  ); */
  box-shadow: inset 0px -1px 3px 1px rgba(0, 0, 0, 0.3);
}

.active,
.settings-nav button:hover {
  border-bottom: none;
  color: #279841;
  background: linear-gradient(0deg,
      rgba(250, 250, 250, 0) 10%,
      rgba(250, 250, 250, 0.1) 70%,
      rgba(250, 250, 250, 0.5) 100%);
  box-shadow: inset 0px -1px 3px 1px rgba(0, 0, 0, 0.3);
}

label {
  display: flex;
  flex-direction: column;
}

input::file-selector-button {
  /* font-weight: bold; */
  /* color: dodgerblue; */
  padding: 0.125rem 0.5rem;
  /* border: thin solid grey; */
  border-radius: 0px 8px;
  margin-top: -0.18rem;
}

.header-init {
  animation: header-init 1s ease-in;
}

input,
[type="text"],
[type="phone"],
[type="password"],
[type="email"],
[type="url"],
[type="color"],
[type="datetime-local"],
[type="select"],
.signup-container:not() select {
  background-color: rgba(172, 202, 220, 0.51);
  border-radius: 0px 10px;
  border: 0;
  animation: field-init 0.5s ease-in;
  height: 1rem;
  padding: 0.5rem;
  /* width: 100%; */
  /* min-width: 150px; */
  /* margin: auto; */
  margin: 0.25rem auto;
  /* color: #533131; */
  border: 1px solid #fffafa;
  /* box-shadow: 0px 0 5px 2px rgba(255, 255, 255, 0.3); */
  box-shadow: inset 1px 1px 2px 1px rgb(44, 34, 34),
    0px 0 5px 2px rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  z-index: 10;
}

[type="select"] {
  height: 2rem;
}

[type="color"] {
  height: 2rem;
  width: 4rem;
  padding: 0.125rem 0.25rem;
}

[type="checkbox"] {
  box-shadow: none;
}

[name="margin"] {
  width: 80px;
}

[name="width"],
[name="height"] {
  width: 45px;
}

/* input,
.qr-form-wrap [type="text"],
.qr-form-wrap [type="color"],
.qr-form-wrap [type="select"] {
 max-width: 10px,
} */
.qr-form-wrap [type="text"] {
  height: 0.75rem;
}

button {
  max-width: 200px;
  margin: auto;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.persona-title-bar {
  /* background-color: #ffffff18; */
  /* filter: opacity(0.2); */
}

.metric-list-card,
.follow-list-card {
  background-color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(-5px);
  color: #000;

  /* border-radius: 0px; */
  /* transform: translate(-1px, -1px); */
  /* border: 1px solid #000; */
  /* box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2); */
}

.fieldMode-container {
  background-image: url("../public/assets/BodyBG2.svg");
  background-size: cover;

}

.metric-list-card:hover,
.follow-list-card:hover,
.netric-card-btn:hover {
  background-color: rgba(146, 251, 181, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(-5px);
  color: #000;
  /* border-radius: 0px; */
  /* transform: translate(-1px, -1px); */
  /* border: 1px solid #000; */
  /* box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2); */
}

.refresh-button:hover,
.refresh-button:focus {
  /* box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.3); */
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.settings-gear:hover,
.settings-gear:focus,
.lock-logo {
  /* box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.3); */
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

.scaler {
  animation-name: scaleUp;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  /* animation-timing-function: linear; */
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

.rot180 {
  animation-name: rot180;
  transition: linear ease-in-out;
  animation-duration: 500ms;
  animation-iteration-count: 1;
}

.rot-180 {
  animation-name: rot-180;
  transition: ease-in-out;
  animation-duration: 200ms;
  animation-iteration-count: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rot180 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes rot-180 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes scaleUp {

  0%,
  100% {
    transform: scale(0.3);
  }

  50% {
    transform: scale(1);
  }
}

.text-shadow-01 {
  color: #ffffff;
  /* background: #333333;  */
  text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
    #ff2d95 0px 0px 20px, #ff2d95 0px 0px 30px, #ff2d95 0px 0px 40px,
    #ff2d95 0px 0px 50px, #ff2d95 0px 0px 75px;
  color: #ffffff;
  /* background: #333333; */
}

.text-shadow-02 {
  color: #ffffff;
  /* background: #333333;  */
  text-shadow: #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 15px,
    #922dff 0px 0px 20px, #922dff 0px 0px 30px, #922dff 0px 0px 40px,
    #922dff 0px 0px 50px, #922dff 0px 0px 75px;
  color: #000000;
  /* background: #333333; */
}

.text-shadow-term {
  color: #ffffff;
  /* background: #333333;  */
  text-shadow: #000 0px 0px 5px, #000 0px 0px 10px, #000 0px 0px 15px,
    #922dff 0px 0px 20px, #922dff 0px 0px 30px, #922dff 0px 0px 40px,
    #922dff 0px 0px 50px, #922dff 0px 0px 75px;
  color: #00FF00;
  /* background: #333333; */
}

.text-shadow-term-green {
  color: #ffffff;
  /* background: #333333;  */
  text-shadow: #000 0px 0px 5px, #000 0px 0px 10px, #000 0px 0px 15px,
    #00FF00 0px 0px 20px, #00FF00 0px 0px 30px, #00FF00 0px 0px 40px,
    #00FF00 0px 0px 50px, #00FF00 0px 0px 75px;
  color: #00FF00;
  /* background: #333333; */
}

.is-sticky {
  position: fixed;
  top: 10vh;
  /* top: 10vh; */
  z-index: 0;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.is-sticky2 {
  position: sticky;
  top: 0;
  /* top: 10vh; */
  right: 0;
  z-index: 99;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.is-top {
  /* position: fixed; */
  /* top: 0px; */
  z-index: 19999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.is-bottom {
  /* position: fixed; */
  /* top: 0px; */
  z-index: 0;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.persona-card-pic::before {
  /* content: "";
 width: 200px;
 height: 200px;
  border: 2px solid red;
  border-radius: 100%;  */
}

.manager-add-button:hover,
.button-accent-hover:hover {
  animation: 150ms ease-in 0s normal none 1 running hover-rotate;
}

.manager-delete-button:hover,
.button-woddle:hover {
  animation: hover-shake 0.5s infinite ease-in-out
}

.constant-woddle {
  animation: hover-shake 0.5s infinite ease-in-out
}

@keyframes hover-shake {

  0%,
  100% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);

  }

  75% {
    transform: rotate(-10deg);

  }
}

@keyframes hover-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(10deg);

  }
}

@keyframes field-init {
  0% {
    height: 0em;
  }

  100% {
    height: 1rem;
  }
}

@keyframes header-init {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes QR-init {
  0% {
    height: 0%;
  }

  100% {
    height: 40dvh;
  }
}

.blink {
  animation: blinker 3s ease-in-out infinite;
}

.blink-2 {
  animation: blinker 6s ease-in-out infinite;
}

@keyframes blinker {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.color-grad-blue-border {
  animation: color-bl-wt 5s ease-in-out infinite;
}

@keyframes color-bl-wt {

  0%,
  100% {
    border-color: #6ca2f9;
  }

  50% {
    border-color: #ffffff;
    boxshadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.6),
      inset 0px 0px 10px 10px rgba(253, 156, 0, 0.3);
  }
}

.color-grad-green-border {
  animation: color-gr-wt 7s ease-in-out infinite;
}

@keyframes color-gr-wt {

  0%,
  100% {
    border-color: #85ff91;
  }

  50% {
    border-color: #ffffff;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.6),
      inset 0px 0px 15px 15px rgba(255, 255, 255, 0.3);
  }
}

#scroll-container,
div[id*="-scroll-container"],
#hover-scroll-container:hover,
#hover-scroll-container:focus

/* ,#hover-scroll-container:active */
  {
  /* border: 3px solid black; */
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
  width: 95%;
  height: 1.5em;
  white-space: nowrap;
}

/* @import url('https://fonts.cdnfonts.com/css/lcd'); */
#scroll-container-2

/* ,#hover-scroll-container:active */
  {
  /* border: 3px solid black; */
  /* font-family: 'LCD', sans-serif; */
  /* font-family: 'LcdSolid-VPzB', sans-serif; */
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 1.5em;
  white-space: nowrap;
  /* text-wrap:no-wrap; */
}

#scroll-container-3

/* ,#hover-scroll-container:active */
  {
  /* border: 3px solid black; */
  /* font-family: 'LCD', sans-serif; */
  /* font-family: 'LcdSolid-VPzB', sans-serif; */
  margin: auto;
  /* border-radius: 5px; */
  overflow: hidden;
  /* width: 100%; */
  height: 1.5em;
  white-space: nowrap;
  /* text-wrap:no-wrap; */
}

div[id*="-scroll-container"],
#hover-scroll-container:hover,
#hover-scroll-container:focus

/* #hover-scroll-container:active  */
  {
  /* color: yellow; */
  white-space: nowrap;
  width: 100%;
}

/* #scroll-text:hover { */

#scroll-text

/* #hover-scroll-text:hover,
  #hover-scroll-text:focus
 ,#hover-scroll-text:active  */
  {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 13s linear infinite;
  -webkit-animation: my-animation 13s linear infinite;
  animation: my-animation 13s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* toggle Switch */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-sml {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 11px;
}

/* Hide default HTML checkbox */
.switch input,
.switch-sml input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider,
.slider-sml {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* ---sml */
.slider-sml:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 1px;
  bottom: 0px;
  /* background-color: white; */
  border: 2px solid #fff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider-sml {
  /* background-color: rgba(61, 255, 158, 0.628); */
  background-color: rgba(253, 0, 0, 0.628);
  background-color: #2196F3;
}

input:focus+.slider-sml {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider-sml:before {
  -webkit-transform: translateX(7px);
  -ms-transform: translateX(7px);
  transform: translateX(7px);
}

/* Rounded sliders */
.slider-sml.round {
  border-radius: 34px;
}

.slider-sml.round:before {
  border-radius: 50%;
}



/* .box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
} */

.img-preview {
  overflow: hidden;
}

#reset-this-root {
  all: initial;
}

.bg-blur {
  backdrop-filter: blur(10px);
}

.swiper-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

}

canvas {
  width: 100%;

  /* min-width: 100%;

  min-height: 100%; // or 
  height: 100%; */
}

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
}

/* CustomAudioPlayer.css */
.rhap_container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* width: 100%; */
  /* border: 1px solid red; */
}

.rhap_controls-section {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  gap: 0.5rem;
  width: 100%;
  /* min-width: 360px; */
}

.custom-progress-bar {
  /* position: relative; */
  z-index: 99999;
  /* background-color: #fff; */
  background-image: linear-gradient(to top, #ffffff, #ffffff80, #ffffff40, #ffffff00, #ffffff00);
  backdrop-filter: blur(5px);
  border: 1px solid #757575;
  /* border-radius: 10px 10px 0 0; */
  width: 100%;
  /* width:350px; */
  /* min-width:350px;*/
  height: 100%;
  height: 1.5rem;
  /* content:'&nbsp'; */
  margin: auto;
}

.custom-progress-bar-2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  /* background-color: #fff; */
  /* background-image: linear-gradient(to top, rgba(0,255,0,0.4), #ffffff80, #ffffff40, #ffffff00, #ffffff00); */
  background-image: linear-gradient(to top, rgba(0, 255, 0, 0.4), #ffffff00);
  box-shadow: 0px 0px 5px 3px rgba(0, 255, 0, 0.5), inset 0px 0px 5px 3px rgba(0, 255, 0, 0.5);
  backdrop-filter: blur(5px);
  border: 1px solid #00FF00;
  color: #00FF00;
  /* border-radius: 10px 10px 0 0; */
  width: 100%;
  /* width:350px; */
  /* min-width:350px;*/
  height: 100%;
  height: 1.5rem;
  /* content:'&nbsp'; */
  margin: auto;
}

.playlist-title-bar {
  /* position: absolute;
  top: 0;
  left: 0; */
  z-index: 99999;
  /* background-color: #fff; */
  /* background-image: linear-gradient(to top, rgba(0,255,0,0.4), #ffffff80, #ffffff40, #ffffff00, #ffffff00); */
  background-image: linear-gradient(to top, rgba(0, 255, 0, 0.4), #ffffff00);
  box-shadow: 0px 0px 5px 3px rgba(0, 255, 0, 0.5), inset 0px 0px 5px 3px rgba(0, 255, 0, 0.5);
  backdrop-filter: blur(5px);
  border: 1px solid #00FF00;
  color: #00FF00;
  width: 100%;
  height: 100%;
  height: 1.5rem;

  margin: auto;
}

.audio-player-container .rhap_progress-bar {
  transition: box-shadow 0.3s ease;
}

.audio-player-container.loaded .rhap_progress-bar {
  box-shadow: 0px 0px 10px rgba(255, 204, 0, 0.7);
}

.player .rhap_progress-bar {
  background-color: #ffcc00;
  /* Change the background color */
  height: 4px;
  /* Change the height */
  width: 100%;
  min-width: 150px;
  border-radius: 10px;

  /* Change the border radius */
}

#playlistPlayer .player .rhap_time {
  color: #FF00FF;
  /* font-family: ' LCD2,sans-serif', */
}

#playlistPlayer .player {
  --rhap_font-family: "LCD2", sans-serif;
}

.player .rhap_progress-bar-show-download {
  /* background-color: #f50; */
  background-color: #ffcc00;
  border: 1px solid #ffcc00;

  /* Change this to your desired color */
}

.player .rhap_progress-indicator {
  /* top:50%; */
  background-color: #ff6600;
  /* Change the indicator color */
}

.player .marker {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 99999;
}

#portrait,
.small-image {
  /* position: relative; */
  animation: floater 3s linear infinite;
}

@-moz-keyframes floater {

  0%,
  100% {
    /* bottom: 0; */
    -moz-transform: rotate(-2deg);
  }

  25% {
    -moz-transform: translateY(-1rem);
  }

  45% {
    -moz-transform: rotate(0deg);
  }

  50% {
    /* bottom: 25px; */
    -moz-transform: translateY(0rem);

  }

  75% {
    -moz-transform: rotate(-5deg);
    -moz-transform: translateY(1rem);
  }
}

@-webkit-keyframes floater {

  0%,
  100% {
    /* bottom: 0; */
    -webkit-transform: rotate(-2deg);
  }

  /* 20% {
        -webkit-transform: rotate(5deg);
    } */
  25% {
    /* -webkit-transform: rotate(5deg); */
    -webkit-transform: translateY(-1rem);
  }

  45% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    /* bottom: 25px; */
    -webkit-transform: translateY(0rem);
  }

  75% {
    -webkit-transform: rotate(-5deg);
    -webkit-transform: translateY(1rem);
  }
}

@keyframes floater {

  0%,
  100% {
    /* bottom: 0; */
    transform: rotate(-2deg);
  }

  /* 20% {
        transform: rotate(2deg); 
        } */

  25% {
    /* transform: rotate(5deg); */
    transform: translateY(-1rem);
    /* transform: translateY(-5rem); */
  }

  45% {
    transform: rotate(0deg);
  }

  50% {
    /* bottom: 25px; */
    /* transform: rotate(0deg); */
    transform: translateY(0rem);
  }

  65% {
    /* transform: rotate(2deg); */
  }

  75% {
    /* transform: rotate(-5deg); */
    transform: translateY(1rem);
  }
}

.small-image {
  z-index: 0;
  top: 45%;
  left: 36%;
  /* transform: scale(0.4);  */
  /* animation: orbit 10s linear infinite; */
  position: absolute;
  height: 10%;
  width: auto;
  /* position: absolute; */
  /* width: 40px;
        height: 40px; */
  /* background-color: #f39c12; */
  /* Orange color for the moon */
  /* border-radius: 50%; */
  animation: rotateMoon 13s linear infinite;
  transition-timing-function: ease-in;


}

@keyframes rotateMoon {
  from {
    transform: rotate(0deg) translateX(150px) rotate(0deg);
  }

  to {
    transform: rotate(-360deg) translateX(150px) rotate(360deg);
    z-index: 3;
  }
}


.rot-bg {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%,-50%); */
  width: 260px;
  height: 260px;
  border-radius: 100%;
  overflow: hidden;
  /* border: 1px solid red; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background: linear-gradient(0deg,
      rgba(18, 38, 92, 0.69) 0%,
      rgba(17, 13, 39, 0.779) 35%,
      rgba(30, 22, 68, 0.697) 50%,
      rgba(255, 151, 47, 0.718) 55%,
      rgba(255, 234, 47, 0.718) 60%,
      rgba(93, 240, 248, 0.548) 65%,
      rgba(93, 240, 248, 0.548) 95%,
      rgba(255, 234, 47, 0.718) 100%); */
  animation: bg-grad-2 16s infinite;
  transition: animation ease-in-out 0.75s;
  z-index: 0;
  /* opacity: 0.5; */
}

.sphere-wrap {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 260px;
  /* width: 100%;
  height: 100%; */
  border-radius: 100%;
  overflow: hidden;
  /* border: 1px solid red; */
  /* transform: translate('50%','0%'); */
}

.cloud-div {
  position: 'absolute';
  transform: translate(-100%, 0%);
  top: 50%;
  left: 50%;
  /* height: 100%;
  width: 100%; */

  opacity: 0.75;
  animation: back-forth 30s infinite;
}

.cloud-div2 {
  position: 'absolute';
  transform: translate(-100%, -100%);
  top: 0;
  right: 0;
  height: 50%;
  /* width: 50%; */

  opacity: 0.75;
  animation: forth-back 30s infinite;
}

@keyframes forth-back {

  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(50%);
  }

  50% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-50%);
  }
}

@keyframes back-forth {

  0%,
  100% {
    transform: translateX(100%);
  }

  25% {
    transform: translateX(50%);
  }

  50% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-50%);
  }
}

/* .login-top {
  width: 100%;
  height: 500%;
  text-align: center;
  margin: auto;
} */

.sun img {
  width: 100px;
  height: auto;
  /* animation: bg-grad-2 10s ease-in-out infinite; */
}

.moon img {
  width: 100px;
  height: auto;
  /* animation: bg-grad-2 60s ease-in-out infinite; */
}

@keyframes bg-grad-2 {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    transform: rotate(180deg);
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.term-text {
  animation: term-text 6.5s step-end infinite alternate;
  z-index: 99;
}

.term-text a {
  padding: .5em;
}

.term-link:hover {
  text-decoration: underline;
}

@keyframes term-text {

  0%,
  100% {
    /* background-color: rgba(255, 255, 255, 0); */
    color: #757575;
    text-shadow:
      0.025em 0.04em 0 #fffc00;
  }

  20% {
    /* background-color: rgba(0, 0, 0, 0.074); */
    color: limegreen;
  }

  30% {
    /* background-color: #fff; */
    color: limegreen;
  }

  40% {
    /* background-color: #000; */
    color: limegreen;
    text-shadow: -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #00ff04;
  }

  /* 45%{
      background-color: #fff;
      color: #757575;
    } */
  55% {
    /* background-color: #000; */
    color: #868686;
    text-shadow: 0.05em 0 0 #ffe70b, 0.025em 0.04em 0 #00ff04;
  }

  60% {
    /* background-color: rgba(0, 0, 0, 0.224); */
    color: #868686;
    text-shadow: 0.025em 0.04em 5 #00ff04;
    /* background-color: #fff;
      color: #757575; */
  }
}

#term-button-cls {
  display: flex;
  position: absolute;
  left: 0.125rem;
  top: 0.1rem;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: #f66969;
  padding: 0.25em;
  cursor: pointer;
  font-family: futura bold;
  /* content: "x"; */
  align-items: center;
  text-align: center;
  /* align-content: center; */
  justify-content: center;
  margin: 0;
  z-index: 1999;
}

/* * {
   transition: 0.2s ease-in-out;
 } */

.typing {
  /* width: 35ch; */
  animation: typing 1s steps(29), blink2 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  /* font-family: monospace; */
  font-size: 1em;
}

.typing-med {
  width: 29ch;
  animation: typing 2s steps(29), blink2 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  /* font-family: monospace; */
  font-size: 1em;
}

@keyframes typing {
  from {
    width: 0;
  }

  /* to{
        width: 75%;
    } */
}

@keyframes blink2 {
  50% {
    border-color: transparent;
  }
}

.share-row-text-color,
.custom-pagination {
  color: #00FF00;
  /* Replace with your desired color */
}