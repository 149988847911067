body,
html {
    /* height: 100%;
    width: 100%; */
    height: 100vh;
    width: 100vw;
    font-family: 'Times New Roman', Times, serif;
}

#body {
    position: relative;
    /* overflow-x: hidden; */
    overflow: hidden;
    /* padding: .5em; */
    left: 0%;
    top: 0%;
    margin: 0;
    width: 98%;
}
/* .h3{
    font-family: 'Protest Riot',sans-serif;
} */
.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.hor-center{
    position: absolute;
    left: 50%;
    transform: translate(-50%,0%);
}
.parallax {
    /* The image used */
    /* background-image: url('https://www.w3schools.com/howto/img_parallax.jpg'); */
    /* background-image: url('../../public/assets/stills/QauRa_Still_01.png'); */
    /* background-image: url('../../public/assets/BodyBG2.svg'); */

    /* Full height */
    height: 100%;
    min-height: 400px;
    width: 100%;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: .5em; */
    margin: 0;
}

.p2 {
    /* background-image: url('assets/Folded_Graphic_TSpread.jpg'); */
    /* background-image: url('../../public/assets/BodyBG2.svg'); */
    background-attachment: fixed;
    background-position: cover;
    background-repeat: no-repeat;
}

.p2 img {
    /* transform: rotate(90deg); */
    /* width: 100vw; */
    cursor: pointer;
}

.p3 {
    background-attachment: fixed;
    /* background-image: url('assets/Gridwalker_WL_WHT_01.png'); */
    background-repeat: no-repeat;
    /* background-size: 1000px auto; */
    /* margin-top: -250px; */
    /* padding: .5em; */
}

.p4 {
    background-attachment: fixed;
    /* background-image: url('assets/GrowTheBreath_T_WHT.png'); */
    background-repeat: no-repeat;
    
    /* background-size: 1000px auto; */
    /* clip-path: polygon(50% 100%, 0% 100%, 0% 100%, 100% 0%); */

}

.p5 {
    background-attachment: fixed;
    /* background-image: url('assets/Gridwalker_glitch.gif'); */
    background-repeat: no-repeat;
    /* background-size: 815px auto; */
    /* background-color: #fff; */
    /* margin-bottom: -40px; */

    /* height: 350px; */
    /* padding: .5em; */
    width: 100vw;
    /* border: 1px solid red; */
}

.p6 {
    background-attachment: fixed;
    /* background-image: url('assets/GLOAUN_star_overlay.gif'); */
    background-repeat: no-repeat;
    background-size: 90vw auto;
    background-color: #fff;
    /* clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 90%); */
    /* -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 100%, 0 50%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 100%, 0 50%); */
    /* z-index: 1000; */
    /* margin-bottom: -250px; */
    box-shadow: inset -10px 0px 15px 4px rgba(0, 0, 0, 0.322), 0px 0px 15px 4px rgba(0, 0, 0, 0.322);

    /* height: 350px; */
    /* padding: .5em; */
    /* width: 100vw; */
    /* border: 1px solid red; */
}

.p7 {
    display: flex;
    background-attachment: fixed;
    /* background-image: url('assets/Sage_WL_WHT_01.png'); */
    background-repeat: no-repeat;
    background-size: 750px auto;
    background-position: center;

    background-position: bottom 0% left 48%;
    background-color: #fff;
    border-bottom: 1px solid transparent;
    border-right: 50px solid transparent;
    /* box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.322); */
    /* height: 350px; */
    /* padding: .5em; */
    width: 100%;
    white-space: nowrap;
    /* border: 1px solid red; */
}

.p8 {
    display: flex;
    background-attachment: fixed;
    /* background-image: url('assets/Sage_WL_WHT_03.png'); */
    background-repeat: no-repeat;
    background-size: 750px auto;
    background-position: center;
    background-position: bottom 0% left 48%;
    background-color: #fff;
    white-space: nowrap;
    /* border-bottom: 1px solid transparent;
    border-right: 50px solid transparent; */
    /* box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.322); */
    /* height: 350px; */
    /* padding: .5em; */
    width: 100%;
    /* border: 1px solid red; */
}

#upper1 {
    background-attachment: fixed;
    /* background-image: url('assets/Origins_AOP_BLK_HLS_FR.png'); */
    background-repeat: no-repeat;
    /* background-size: 1000px auto; */
    /* padding: .5em; */
    border-radius: 0px 0px 0px 0px;

}

#upper2 {
    background-attachment: fixed;
    /* background-image: url('assets/Origins_AOP_BLK_SH_BK.png'); */
    background-repeat: no-repeat;
    border-radius: 0px 0px 0px 0px;
  
    /* clip-path: polygon(0% 0, 100% 0%, 0% 100%, 0 80%); */
    /* -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 100%, 0 50%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 50% 100%, 0 50%); */
}

#upper3 {
    /* height: 500px; */
    background-color: #fff;
    font-size: 36px;
    background-attachment: fixed;
    /* background-image: url('../../public/assets/BodyBG_alpha.png'); */
    /* background-image: url('assets/SS-SB_LT_WHT_FR.jpg'); */
    background-repeat: no-repeat;
    /* background-position: right 10%; */
    /* border-right: 50px solid transparent; */
    /* background-size: 1000px auto; */
    /* padding: .5em; */
    border-radius: 0px 0px 0px 0px;
    transition: 0.1s ease-in-out;
    
}

#upper4 {
    height: 600px;
    background-color: #fff;
    font-size: 36px;
    background-attachment: fixed;
    /* background-image: url('assets/SS-SB_LT_WHT_BK.jpg'); */
    background-position: right 10%;
    background-repeat: no-repeat;
    border-radius: 0px 0px 0px 0px;

}

.color-grad-mult-inner {

    /* -webkit-animation: color-yl-wt 5s ease-in-out infinite; */
    /* animation: color-mlt-in 5s ease-in-out infinite; */
    /* animation: color-single-in 6s ease-in-out infinite; */
}

@keyframes color-single-in {
    0% {
        border-top: 4px solid rgba(255, 246, 123, 0.575);
    }

    /* box-shadow:inset 0px 0px 15px 2px  rgba(255,247,123,1),0px 0px 10px 2px  rgba(255,247,123,1);} */
    25% {
        border-top: 3px solid rgba(255, 247, 123, 1);
    }

    /* box-shadow:inset 0px 0px 10px 3px  rgba(255,247,123,1),0px 0px 15px 3px  rgba(255,247,123,1);} */
    50% {
        border-top: 1px solid rgba(255, 246, 123, 0.322);
    }

    /* box-shadow:inset 0px 0px 15px 2px  rgba(255, 246, 123, 0.548),0px 0px 15px 1px  rgba(255, 246, 123, 0.534);   } */
    75% {
        border-top: 4px solid rgba(255, 247, 123, 1);
    }

    /* box-shadow:inset 0px 0px 10px 4px  rgba(255,247,123,1),0px 0px 15px 4px  rgba(255,247,123,1); } */
    85% {
        border-top: 2px solid rgba(255, 246, 123, 0.418);
    }

    /* box-shadow:inset 0px 0px 15px 2px rgba(255, 246, 123, 0.541),0px 0px 15px 2px  rgba(255, 246, 123, 0.493); } */
    100% {
        border-top: 4px solid rgba(255, 247, 123, 1);
    }

    /* box-shadow:inset 0px 0px 15px 4px rgba(255, 246, 123, 0.541),0px 0px 15px 4px  rgba(255, 246, 123, 0.493); } */
}

.upperLayer {
    position: relative;
    /* box-shadow: 0px 0px 10px 5px #868686; */
    /* background-color: blue; */
    width: 100%;
    height: auto;
    border-bottom: 3px ridge rgba(0, 0, 0, 1);
    border-left: none;
    border-right: none;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.5);
}

.upperLayerTotes {
    position: relative;
    /* box-shadow: 10px 0px 10px 5px #86868662; */
    /* background-color: blue; */
    height: 400px;
    width: 100%;

    /* border-radius: 50px 0px 50px 0px; */
    padding: .5em;
    text-align: center;
}

#origins-header {
    position: absolute;
    right: 20%;
    top: 65%;
    font-size: 48pt;
    text-shadow: #fff;
}

span[id *="end-copy-"] {
    position: relative;
    /* display: flex; */

    right: 0px;
    top: 65%;
    font-size: 48pt;
    text-shadow: #fff;

}

#totes-header {
    font-size: 48pt;
    margin: 0;
}

.header {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: none;
    top: 0px;
    left: 0;
    /* right:0; */
    height: 100px;
    width: 100vw;
    /* padding-top: 1em; */
    /* padding-right: 1em; */
    white-space: nowrap;
    /* margin: auto -.5em; */
    z-index: 3000;
    overflow: hidden;
    color: #868686;
    font-family: futura;
    font-size: 1em;
    text-align: center;
    align-items: start;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
}

.header img {
    width: 40px;
    height: auto;
    padding: 5px;
}

#tote-box img {
    width: 300px;
    height: auto;


}

#tote-box {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    width: 90vw;
    border-radius: 50px 0px 50px 0px;
    /* border: 1px solid rgb(57, 49, 49); */
}

.footer {
    position: sticky;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: none;
    bottom: 0;
    left: 0;
    /* right:0; */
    height: 50px;
    width: 100vw;
    padding-top: 1em;
    /* padding-right: 1em; */
    white-space: nowrap;
    /* margin: auto -.5em; */
    z-index: 3000;
    overflow:hidden;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 44%, rgba(255, 255, 255, 0) 100%);
}